import * as React from "react"
import { GatsbyImage } from 'gatsby-plugin-image'

// import LightboxImage from "./lightboxImage"
import TextSection from "./textSection"
import Button from "./button"
import "./iconSection.css"

const IconSection = ( { data, location } ) => {

  var textSectionData = Object.assign({}, data);
  delete textSectionData.button_text;

    return (

      <div className={`${location}-icons`}>

        {data.title_visible &&
          <TextSection className={`description-medium ${data.align}`} data={textSectionData} />
        }

        <div className="icons-grid-container page-section">

            {data.image &&
              <div className="image-container">
                {/* <LightboxImage image={image} /> */}
                <GatsbyImage
                  image={data.image.childImageSharp.gatsbyImageData}
                />
              </div>
            }

            <div className={`icons-grid ${data.type} ${data.image ? "" : "no-image"}`}>
            {data.icons.map((icon) => (
                <div className="icon-wrapper">
                  <GatsbyImage
                    image={icon.image.childImageSharp.gatsbyImageData}
                    alt={icon.title}
                  />
                  <div className="icon-txt-wrapper">
                    <h4 className="icon-title">
                      {icon.title}
                    </h4>
                    <p className="icon-desc">
                      {icon.text}
                    </p>
                  </div>
                </div>
              ))}
            </div>

        </div>

        {data.button_text &&
          <Button btnStyle={data.button_style} btnLink={data.button_url} btnText={data.button_text} />
        }

      </div>

    )
  }

export default IconSection