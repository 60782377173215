import * as React from "react"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "../components/container"
import AnimationWrapper from "../components/animationWrapper"
import TextSection from "../components/textSection"
import Slider from "../components/swiper"
import IconSection from "../components/iconSection"
import DescriptionSection from "../components/descriptionSection"
import AchievementsSection from "../components/achievementsSection"
import TestimonialsSection from "../components/testimonialsSection"
import ContactSection from "../components/contactSection"

import "./index.css"

const IndexPage = ( {data, location} ) => {

  return (

    <Layout location={location}>

      <Seo title={data.page.frontmatter.title} />

      {data.page.frontmatter.slides[0] &&
        <section id="home-landing" className="page-section">
          <AnimationWrapper>
            <Slider data={data.page.frontmatter.slides} />
          </AnimationWrapper>
        </section>
      }

      {data.page.frontmatter.text_section &&
        <Container>
          <AnimationWrapper>
            <TextSection className="description-medium  homepage-desc" data={data.page.frontmatter.text_section} />
          </AnimationWrapper>
        </Container>
      }

      {data.page.frontmatter.icon_section &&
        <section id="homepage-icons">
          <Container>
            <AnimationWrapper>
              <IconSection data={data.page.frontmatter.icon_section} location="homepage" />
            </AnimationWrapper>
          </Container>
        </section>
      }

      {data.page.frontmatter.description_section_inwestor &&
        <section id="inwestor-desc" className="page-section">
          <DescriptionSection location="inwestor" data={data.page.frontmatter.description_section_inwestor} />
        </section>
      }

      {data.page.frontmatter.achievements_section &&
        <section id="realizacje-domylc-achievements">
         <Container>
           <AnimationWrapper>
             <AchievementsSection data={data.page.frontmatter.achievements_section} location="homepage" />
           </AnimationWrapper>
         </Container>
       </section>
      }

      {data.page.frontmatter.testimonials_section &&
        <section id="testimonials" class="page-section">
          <AnimationWrapper>
            <TestimonialsSection textSectionData={data.page.frontmatter.testimonials_section} testimonials={data.testimonials.nodes}  />
          </AnimationWrapper>
        </section>
      }

      {data.page.frontmatter.contact_section &&
        <section id="contact-data" className="page-section">
          <ContactSection data={data.page.frontmatter.contact_section} />
        </section>
      }

    </Layout>
  )
}

export const pageQuery = graphql`
  query{
    page: mdx(frontmatter: { slug: { eq: "index" }, type: { eq: "page" } }) {
      frontmatter{
        title
        slides{
          title
          image{
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit: COVER},
                webpOptions: {quality: 95}
              )
            }
          }
          button_text
          button_url
          button_style
          text
        }
        text_section{
          title
          title_visible
          align
          subtitle
          # icon{
          #   childImageSharp {
          #     gatsbyImageData(
          #       transformOptions: {fit: COVER},
          #       webpOptions: {quality: 95}
          #     )
          #   }
          # }
          button_text
          button_url
          button_style
          text
        }
        description_section_inwestor{
          title
          title_visible
          subtitle
          align
          button_text
          button_url
          button_style
          text
          image{
            childImageSharp{
              gatsbyImageData(
                webpOptions: {quality: 95}
              )
            }
          }
        }
        icon_section{
          title
          title_visible
          align
          subtitle
          type
          # image{
          #   childImageSharp{
          #     gatsbyImageData(
          #       webpOptions: {quality: 95}
          #     )
          #   }
          # }
          button_text
          button_url
          button_style
          text
          icons{
            title
            text
            image{
              childImageSharp {
                gatsbyImageData(
                  webpOptions: {quality: 95}
                )
              }
            }
          }
        }
        achievements_section{
          title
          title_visible
          subtitle
          type
          # image{
          #   childImageSharp{
          #     gatsbyImageData(
          #       webpOptions: {quality: 95}
          #     )
          #   }
          # }
          text
          achievements{
            value
            postfix
            description
          }
        }
        contact_section{
          title
          description
          type
          fields
          submit_button_style
          image{
            childImageSharp{
              gatsbyImageData(
                webpOptions: {quality: 95}
              )
            }
          }
        }
        testimonials_section{
          title
          title_visible
          subtitle
          type
          image
          text
        }
      }
    }
    testimonials: allMdx(
      sort: { fields: [frontmatter___order], order: ASC }
      filter: {internal: {contentFilePath: {regex: "/testimonials/"}}}
    ){
      nodes {
        frontmatter {
          title
          slug
          order
          active
          text
        }
      }
    }
  }
`

export default IndexPage
